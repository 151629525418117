export const parseSpacingInline = (spacingString: string | undefined) => {
  if (!spacingString) return ''

  const values = spacingString.trim().split(/\s+/)

  switch (values.length) {
    case 1:
      return `${values[0]};`
    case 2:
      return `${values[0]} ${values[1]};`
    case 3:
      return `${values[0]} ${values[1]} ${values[2]};`
    case 4:
      return `${values[0]} ${values[1]} ${values[2]} ${values[3]};`
    default:
      return ''
  }
}
