import { Container, ContainerProps } from '@chakra-ui/react'

export interface UiContainerProps extends Omit<ContainerProps, 'size'> {
  size?: null | 'full' | '2xl' | 'xl' | 'lg' | string
  children: null | ContainerProps['children']
  marginTop?: null | ContainerProps['marginTop']
  marginBottom?: null | ContainerProps['marginBottom']
  backgroundColor?: string
}

export const UiContainer = ({
  children,
  size,
  marginBottom,
  marginTop,
  backgroundColor,
  ...rest
}: UiContainerProps) => {
  const _size = size?.toLowerCase() ?? 'full'
  const _maxWidth = `container.${_size}`

  return (
    <Container
      maxWidth={_maxWidth}
      px={{ base: 'unset', lg: _size === 'full' ? '0' : 'unset' }}
      marginBottom={marginBottom ?? undefined}
      marginTop={marginTop ?? undefined}
      style={{ backgroundColor }}
      {...rest}
    >
      {children}
    </Container>
  )
}
